exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-admin-fontawesome-tsx": () => import("./../../../src/pages/admin/fontawesome.tsx" /* webpackChunkName: "component---src-pages-admin-fontawesome-tsx" */),
  "component---src-pages-admin-login-tsx": () => import("./../../../src/pages/admin/login.tsx" /* webpackChunkName: "component---src-pages-admin-login-tsx" */),
  "component---src-pages-computer-laptop-repair-locations-json-slug-tsx": () => import("./../../../src/pages/computer-laptop-repair-{locationsJson.slug}.tsx" /* webpackChunkName: "component---src-pages-computer-laptop-repair-locations-json-slug-tsx" */),
  "component---src-pages-computer-pc-brands-json-slug-computer-pc-repair-tsx": () => import("./../../../src/pages/{computerPcBrandsJson.slug}-computer-pc-repair.tsx" /* webpackChunkName: "component---src-pages-computer-pc-brands-json-slug-computer-pc-repair-tsx" */),
  "component---src-pages-data-recovery-locations-json-slug-tsx": () => import("./../../../src/pages/data-recovery-{locationsJson.slug}.tsx" /* webpackChunkName: "component---src-pages-data-recovery-locations-json-slug-tsx" */),
  "component---src-pages-fee-list-tsx": () => import("./../../../src/pages/fee-list.tsx" /* webpackChunkName: "component---src-pages-fee-list-tsx" */),
  "component---src-pages-gatsby-index-js": () => import("./../../../src/pages/gatsby-index.js" /* webpackChunkName: "component---src-pages-gatsby-index-js" */),
  "component---src-pages-gatsby-page-2-js": () => import("./../../../src/pages/gatsby-page-2.js" /* webpackChunkName: "component---src-pages-gatsby-page-2-js" */),
  "component---src-pages-gatsby-using-ssr-js": () => import("./../../../src/pages/gatsby-using-ssr.js" /* webpackChunkName: "component---src-pages-gatsby-using-ssr-js" */),
  "component---src-pages-gatsby-using-typescript-tsx": () => import("./../../../src/pages/gatsby-using-typescript.tsx" /* webpackChunkName: "component---src-pages-gatsby-using-typescript-tsx" */),
  "component---src-pages-index-1-tsx": () => import("./../../../src/pages/index-1.tsx" /* webpackChunkName: "component---src-pages-index-1-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-it-support-locations-json-slug-tsx": () => import("./../../../src/pages/it-support-{locationsJson.slug}.tsx" /* webpackChunkName: "component---src-pages-it-support-locations-json-slug-tsx" */),
  "component---src-pages-laptop-brands-json-slug-laptop-repair-tsx": () => import("./../../../src/pages/{laptopBrandsJson.slug}-laptop-repair.tsx" /* webpackChunkName: "component---src-pages-laptop-brands-json-slug-laptop-repair-tsx" */),
  "component---src-pages-markdown-remark-frontmatter-slug-tsx": () => import("./../../../src/pages/{markdownRemark.frontmatter__slug}.tsx" /* webpackChunkName: "component---src-pages-markdown-remark-frontmatter-slug-tsx" */),
  "component---src-pages-new-page-tsx": () => import("./../../../src/pages/new-page.tsx" /* webpackChunkName: "component---src-pages-new-page-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-templates-gatsby-using-dsg-js": () => import("./../../../src/templates/gatsby-using-dsg.js" /* webpackChunkName: "component---src-templates-gatsby-using-dsg-js" */)
}

